<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Formulario de postulación"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  {{ convocatoria.cite }}
                </p>
                <p>
                  El/la postulante para ver o descargar el
                  <b>Formulario de postulación</b> previamente debe estar
                  inscrito, en una de las siguientes modalidades <b>A, B1, B2, B3, B4 y B5</b>.
                </p>
                <p>
                  Si no está inscrito diríjase a la opción de
                  <b>Inscripción</b> del menú <b>Admisión</b>.
                </p>
                <p class="mb-0">
                  En la caja de texto de abajo ingrese su <b>cédula de identidad</b> <span class="red--text">sin expedido</span>, vease el siguiente ejemplo:
                  <b>1234567</b>. En caso de tener complemento (<b>1G</b>), registrelo en el campo correspondiente.
                </p>
                <!-- <p class="mb-0">
                  Por seguridad de los datos registrados: 
                  En la caja de texto <b>código</b> ingrese el codigo que le aparece en el formulario de postulación, vease el siguiente ejemplo:
                  <ul>
                    <li>avtycdd</li>
                  </ul>
                </p> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <!-- <v-card-title>Formulario de Postulación y Aptitud</v-card-title> -->
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPostulante()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model.trim="postulante.cedula_dni"
                        label="Cédula de identidad"
                        :rules="[
                          (v) => !!v || 'El campo es requerido',
                          (v) => (!!v && v.length > 3) || 'Mínimo 4 dígitos',
                        ]"
                        hint="1234567"
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-text-field v-model.trim="postulante.complemento" maxlength="2" label="Complemento" :rules="[
                      ]" hint="1G" clearable filled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-menu
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="birthDateFormatted"
                            label="Fecha de nacimiento"
                            append-icon="mdi-calendar"
                            :rules="[(v) => !!v || 'El campo es requerido']"
                            filled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="postulante.fecha_nacimiento"
                          @input="menu_date = false"
                          locale="es"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="2">
                    <v-select
                        label="Modalidad"
                        v-model="postulante.modalidad_id"
                        :items="modalidades"
                        item-text="descripcion"
                        item-value="id"
                        :rules="[(v) => !!v || 'Seleccione una opción']"
                        filled
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="3">
                      <v-text-field
                        v-model.trim="postulante.codigo"
                        type="text"
                        label="Código"
                        :rules="[
                          (v) => !!v || 'El campo es requerido',
                          (v) => (!!v && v.length > 3) || 'Mínimo 4 caracteres',
                        ]"
                        hint="Ejemplo: artssd, el código se encuentra en el form de postulación."
                        clearable
                        filled
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="3">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <div v-if="type_state == 3">
                  <h4 class="grey--text">DATOS PERSONALES</h4>
                  <v-row>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Apellidos y Nombres</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            {{ postulante.paterno }} {{ postulante.materno }}
                            {{ postulante.nombres }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Genero</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.genero }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <h4 class="grey--text mt-5">DATOS DE POSTULACIÓN</h4>
                  <v-row>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>ESFM/UA</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.institucion }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Especialidad</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.especialidad }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Modalidad</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ postulante.sigla_modalidad }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        type="button"
                        large
                        color="info"
                        @click="printFormCheck"
                      >
                        Imprimir formulario
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="type_state === 2">
                  <span class="red--text">
                    No hay registro con la
                    <b>Cédula Identidad o DNA y Fecha de Nacimiento</b> ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card :title="notify_title" :load="notify_load" process="el proceso de admisión"></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import Service from "../utils/general";
import PDFService from "../utils/pdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "admision-impresion",
  components: {
    HeaderTitle,
    NotifyCard,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    load_search: false,
    menu_date: false,
    type_state: 1,
    convocatoria: {
      id: null,
    },
    postulante: {
      cedula_dni: "",
      paterno: "",
      materno: "",
      nombres: "",
      fecha_nacimiento: "",
      institucion: "",
      especialidad: "",
      modalidad: "",
      complemento : "",
      modalidad_id : "",
      // codigo: "",
    },
    modalidades: [],
    confirmDialog: "",
    cedula_dni:"",
    fecha_nacimiento:"",
    complemento:"",
    modalidad_id : ""
  }),
  computed: {
    birthDateFormatted() {
      if (!this.postulante.fecha_nacimiento) return null;

      const [year, month, day] = this.postulante.fecha_nacimiento.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.getModalidades();
    Service.removeModality();
  },
  methods: {
    ...mapActions(["toast"]),
    getModalidades() {
      this.notify_load = true;
      axios
        .get(Service.getUrlAPI() + "admision/modalidad", Service.getHeader())
        .then((response) => {
          this.convocatoria = response.data.convocatoria
            ? response.data.convocatoria
            : { id: null, cite: "" };
          this.modalidades = response.data.modalidades;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_load = false;
          console.error("Parametros no encontrado");
        });
    },

    searchPostulante() {
      if (this.$refs.formp.validate()) {
        this.load_search = true;
        this.type_state = 1;
        this.cedula_dni= this.postulante.cedula_dni,
        this.fecha_nacimiento= this.postulante.fecha_nacimiento,
        this.complemento= this.postulante.complemento?this.postulante.complemento:"",
        this.modalidad_id = this.postulante.modalidad_id
        axios
          .post(
            Service.getUrlAPI() + "admision/postulacion",
            {
              convocatoria_id: this.convocatoria.id,
              cedula_dni: this.postulante.cedula_dni,
              fecha_nacimiento: this.postulante.fecha_nacimiento,
              complemento : this.postulante.complemento?this.postulante.complemento:"",
              modalidad_id : this.postulante.modalidad_id,
              // codigo : this.postulante.codigo,
            },
            Service.getHeader()
          )
          .then((response) => {
            this.load_search = false;
            if (response.data.id == null) {
              this.type_state = 2;
            } else {
              this.type_state = 3;
              this.postulante = response.data;
            }
          })
          .catch(() => {
            this.load_search = false;
            console.error("Postulante no encontrado");
          });
      } else {
        this.toast({ color: "info", text: "Ingrese Cédula de identidad, Fecha de nacimiento y modalidad" });
      }
    },

    printFormCheck() {
      axios
        .post(
          Service.getUrlAPI() + "admision/comprobante",
          {
            convocatoria_id: this.convocatoria.id,
            cedula_dni: this.cedula_dni,
            fecha_nacimiento: this.fecha_nacimiento,
            complemento : this.complemento,
            modalidad_id : this.modalidad_id
          },
          Service.getHeader()
        )
        .then(async (response) => {
          let datos = response.data;
          let codesize = datos.id.toString().length;
          let codigo = "0".repeat(10 - codesize);
          codigo = codigo + datos.id;
          let bodyPdf =await PDFService.bodyPDF(datos, codigo);
          let docDefinition = {
            pageSize: "LETTER",
            pageMargins: [40, 70, 40, 35],
          //  pageMargins: [40, 55, 40, 150],
            header: PDFService.setting().header,
            footer: PDFService.setting().footer,
       //     footer: PDFService.setting().footer3(datos, codigo,currentPage, pageCount, pageSize),
          //  footer: await PDFService.setting().footer2(datos, codigo),
            content: bodyPdf,
          };
          //pdfMake.createPdf(docDefinition).download("FormularioPostulacion.pdf");
          pdfMake.createPdf(docDefinition).open();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
<style lang="css" scoped>
.theme--light.v-pagination .v-pagination__item:focus {
  outline: 0;
}
</style>